<!--开通会员-->
<template>
  <div class="paper-body-index">
    <div class="price-pay-page">
      <div class="pay">
        <div class="price-pay-content pay-container">
          <header>
            <i class></i>
            {{title}}
          </header>
          <main>
           <!-- <p class="vip-range">授权主体：{{subTitle}}</p>-->
            <p class="vip-range">优惠： <span class="vip-desc">{{subTitle}}</span></p>

            <p class="section-title">1.开通时长</p>
            <ul class="duration-list">
              <li
                v-for="(item,index) in memberVIPList"
                :key="index"
                :class="{active: index == currentMonthIndex}"
                @click="clickOpenMonth(index, item)"
              >
                <img class="cur" src="@/assets/images/pay/active.png" alt />
                <div class="duration"><span class="monthText">{{item.month}}个月</span></div>
                <div class="price">
                  <p class="discount-price">限时￥{{item.price}}</p>
                  <p class="init-price">￥{{item.defaultPrice}}</p>
                </div>
              </li>
            </ul>
          <!--  <p class="section-title">
              支付方式
              <a class="agree" @click="agreen">
                <el-radio v-model="agreeRadio" label="1">阅读并同意</el-radio>
                <em>《使用声明》</em>
              </a>
            </p>-->
            <div class="item">
              <p class="title">2.选择支付方式</p>
              <ul class="type-list">
                <li
                        v-for="(item,index) in payType"
                        :key="index"
                        @click="selectPayType(index)"
                        :class="index == currentPayIndex ? 'active' : ''" >
                  <i :class="'iconfont ' + item.icon"></i>
                  <span>{{item.name}}</span>
                  <img class="cur" :src="item.img" alt />
                </li>
              </ul>
            </div>

            <div class="item pay-btn-box">
              <el-button type="primary" @click="openPayPop">点击充值</el-button>
              <a class="agree" @click="agreen">
                <el-radio v-model="agreeRadio" label="1">阅读并同意</el-radio>
                <em>《使用声明》</em>
              </a>
            </div>
          <!--  <p class="pay-tips">
              付款后在个人中心可申请发票。如遇到问题，请点我咨询
              <span>在线客服</span>
            </p>-->
          </main>
        </div>
      </div>
    </div>
   <!-- <Dialog
      :dialogVisible="dialogPayVisible"
      :title="dialogTitle"
      :currentPrice="currentPrice"
      :defaultPrice="defaultPrice"
      @closeDialog="closePayPop"
    />-->
    <Memberwxdialog
            :dialogVisible="dialogTableVisible"
            :dialogPaying= "isPaying"
            :curPreferential="currentPreferential"
            :currentPrice="currentPrice"
            :currentMonth="currentMonth"
            :currentVipLevel="vipType"
            @closeDialog="closePayPop"
            @onPay="changePay"
    />
  </div>
</template>

<script>
import Dialog from "./module/dialog.vue";
import { getSessionUser } from '../../api/auth';
import { message } from '@/utils/resetMessage';
import Memberwxdialog from "./module/memberwxdialog";
import { vipYePaySuccess } from "../../api/charge";
export default {
  components: {
    Memberwxdialog
  },
  data() {
    return {
      isLoggedIn:false,
      title: this.$store.state.user.vipTitle,
      vipType: this.$store.state.user.vipType,
      subTitle: this.$store.state.user.vipSubTitle,
      currentMonthIndex: 0,
      currentMonth:1,
      currentPayType: "wechat", //支付类型
      currentPayIndex: 0, //支付类型下标
      currentPrice: null, //默认价格
      defaultPrice: null,
      currentPreferential: 0, //优惠
      dialogTableVisible: false, //支付弹窗
      payType: [

       {
          icon: "iconweixin",
          name: "微信支付",
          code: "wechat",
          img: require("@/assets/images/pay/arrow2.png")
        }, {
          icon: "iconweixin",
          name: "余额支付",
          code: "balance",
          img: require("@/assets/images/pay/arrow2.png")
        }
      ],
      agreeRadio: "1",
      isPaying:false, //是否还在支付中
      memberVIPList:null,
      standardVIPList: [
        {
          month: 1,
          price: 20,
          defaultPrice: 59
        },
        {
          month: 3,
          price: 50,
          defaultPrice: 149
        },
        {
          month: 12,
          price: 300,
          defaultPrice: 288
        },
        {
          month: 36,
          price: 500,
          defaultPrice: 568
        }
      ],
      seniorVIPList: [
        {
          month: 1,
          price: 20,
          defaultPrice: 99
        },
        {
          month: 3,
          price: 50,
          defaultPrice: 169
        },
        {
          month: 12,
          price: 300,
          defaultPrice: 269
        },
        {
          month: 36,
          price: 500,
          defaultPrice: 699
        }
      ],
     // payTypeList: ["微信支付", "余额支付"],
      dialogPayVisible: false,
      dialogTitle: null
    };
  },
  mounted() {
    this.isLoggedIn = (this.$store.state.user.token !== null || this.$store.state.user.token !== "")
    && (this.$store.state.user.loginNameType!=null )? true : false;
   if(this.$store.state.user.vipType==constants.vipType.standard){
     this.memberVIPList=this.standardVIPList;
   }else if(this.$store.state.user.vipType==constants.vipType.senior){
     this.memberVIPList=this.seniorVIPList;
   }else{
     //其他

   }
    this.currentPrice = this.memberVIPList[0].price;
    this.defaultPrice = this.memberVIPList[0].defaultPrice;
    this.currentMonth=this.memberVIPList[0].month;
  },
  methods: {
    clickOpenMonth(index, params) {
      //设置默认到价格
      this.currentMonthIndex = index;
      this.currentMonth=params.month;
      this.currentPrice = params.price;
      this.defaultPrice = params.defaultPrice;
      this.currentPreferential = 0;//无优惠
    },
    //选择支付方式
    selectPayType(index) {
      this.currentPayIndex = index;
      this.currentPayType = this.payType[index].code;
    },
    //点击充值跳转到支付窗口
    openPayPop() {
      if(this.isLoggedIn){
        // 如果会员等级不一致，//提示
        if(this.$store.state.common.userInfo.userLevelId!=null){//是否已是vip
          //vip未过期，且要充值的是不同级别的vip
          if(this.vipType!=this.$store.state.common.userInfo.userLevelId&&new Date()<=this.$store.state.common.userInfo.vipEndTime){
            message.error({
              message: '您已是VIP会员，无法充值其他等级会员',
              showClose: true,
              duration: 2000,
              offset: 100
            });

            return;
          }
        }
        if(this.currentPayType=='wechat') {//微信支付
          this.dialogTableVisible = true;
        }else {//余额支付
          //判断余额是否足够
          let params={
            currentVipLevel:this.vipType,
            currentMonth:this.currentMonth,
            price :this.currentPrice,
          }
          vipYePaySuccess(JSON.stringify(params)).then((result) => {
            console.log("vipYePaySuccess result:",result);
            message.success({
              message: '您已充值成功，谢谢',
              duration: 2000,
              offset: 80
            })
            this.$store.dispatch("setUserInfo", result);

          }).catch((error) => {
            console.log(JSON.stringify(error));
          })

        }
      }else{
        message.error({
          message: '您还没登录用户，请登录',
          showClose: true,
          duration: 2000,
          offset: 100
        });
      }
    },
    closePayPop() {
      this.currentPayType = null;
      this.dialogTableVisible = false;
      this.getUserList();
    },
    changePay(flag){ //更换支付状态
      this.isPaying = flag;
      //this.getTableData();
    },
    //同意使用声明
    agreen() {
      this.$router.push({ path: "/statement" });
      this.$store.commit("asideMenuId", 7);
    },
    getUserList() {
      getSessionUser({}).then((result) => {
        this.$store.dispatch("setUserInfo", result);
        if (this.$store.state.user.loginNameType) {
          this.account = this.$store.state.user.loginNameType == 1
                  ? this.$store.state.common.userInfo.mobile
                  : this.$store.state.common.userInfo.mail;
          this.balance = result.blance + "元";
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.paper-body-index {
  padding-top: 70px;
}
.pay-btn-box {
  margin-top: 30px;
  .agree {
    margin-left: 40px;
    cursor: pointer;
    .el-radio {
      margin-right: 0;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #8f8f8f;
      cursor: pointer;
    }
    em {
      color: #2a2a2a;
      text-decoration: underline;
    }
  }

}
.item {
  padding-top: 30px;
  border-top: 1px solid #e8e8e8;
  overflow: hidden;
  .title {
    padding-bottom: 30px;
    font-size: 14px;
    color: #767676;
  }
  .list {
    margin-bottom: 30px;
    overflow: hidden;
    li {
      margin-right: 10px;
      padding: 16px 16px 0;
      position: relative;
      float: left;
      width: 132px;
      height: 84px;
      border: 1px solid #e8e8e8;
      border-radius: 10px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 10px;
      &.big {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.bg-gray {
        display: block;
        height: 70px;
        padding: 30px 16px 0;
        background: #f3f3f3;
        color: #999;
        .label {
          padding-top: 5px;
          font-size: 12px;
          color: #c3c3c3;
        }
      }
      &.active {
        border: 1px solid #ff7a03;
        box-shadow: 1px 1px 10px #ffdcbd;
        .cur,
        .rec {
          display: block !important;
        }
      }
      .cur {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
      }
      .rec {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
      }
      .text1 {
        padding-bottom: 8px;
        color: #343851;
      }
      .text2 {
        padding-top: 8px;
        margin: 0 auto;
        margin-bottom: 10px;
        width: 80%;
        border-top: 1px solid #e8e8e8;
        color: #8e909b;
        font-size: 14px;
      }
    }
  }
  .type-list {
    li {
      margin-right: 30px;
      position: relative;
      float: left;
      display: flex;
      width: 178px;
      height: 80px;
      line-height: 80px;
      border: 1px solid #e8e8e8;
      border-radius: 10px;
      color: #7d7d7d;
      font-size: 16px;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      cursor: pointer;
      &:nth-child(2).active,
      &:nth-child(1).active {
        color: #666;
        .cur {
          display: block !important;
        }
      }
      &:nth-child(2).active {
        border: 1px solid #0c9ee0;
      }
      &:nth-child(1).active {
        border: 1px solid #3cb73f;
      }
      &:nth-child(2) {
        color: #0c9ee0;
        .iconfont {
          margin-right: 6px;
          color: #0c9ee0;
        }
      }
      &:nth-child(1) {
        .iconfont {
          margin-right: 15px;
          color: #3cb73f;
        }
      }
      .iconfont {
        font-size: 38px;
      }
      .cur {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
      }
    }
  }
}
.text-item {
  margin-left: 20px;
  display: inline-block;
  .text1 {
    margin-top: 72px;
    font-size: 16px;
    .font-orange {
      font-size: 30px;
    }
  }
  .text2 {
    margin-top: 10px;
    font-size: 16px;
    color: #999;
  }
}
 .monthText{
   font-size: 20px;
   color: #0C0C0C;
   font-weight: 700;//加粗
 }
</style>


