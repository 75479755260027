<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    @close="closeDialog"
    class="table"
    :width="title == '快捷支付' ? '500px' : '300px'"
  >
    <div class="qrcode-way" v-if="title == '快捷支付'">
      <div class="mpay-qrcode-container">
        <div ref="qrcodeContainer" class="qrcode-container"></div>
      </div>
      <div class="mpay-qrcode-tips">
        <p class="pay-info-container">
          应付金额:
          <span class="need-to-pay">￥{{currentPrice}}</span>
          <span v-if="defaultPrice > 0">
            [
            <s class="line-through">￥{{defaultPrice}}</s>]
          </span>
        </p>
        <p class="qr-tip">{{payName}}扫码付款</p>
      </div>
    </div>
    <div v-else class="balance">
      <div class="topay">
        <p>账户余额：￥{{balance}}</p>
        <a class="link" @click="goPay">充值</a>
      </div>
      <div class="mpay-qrcode-tips">
        <p class="pay-info-container">
          应付金额:
          <span class="need-to-pay">￥{{currentPrice}}</span>
          <span v-if="defaultPrice > 0">
            [
            <s class="line-through">￥{{defaultPrice}}</s>]
          </span>
        </p>
      </div>
      <div class="item pay-btn-box">
        <el-button type="primary" @click="submitPay">立即支付</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: ["dialogVisible", "title", "currentPrice", "defaultPrice"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val && this.title == "快捷支付") this.getERCodeDatas();
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      price: 0,
      payType: "wechat",
      payName: "微信",
      balance: "0.00"
    };
  },
  methods: {
    //二维码
    showQRCode(url) {
      //先清空
      this.$refs.qrcodeContainer.innerHTML = "";
      //再生成二维码
      new QRCode(this.$refs.qrcodeContainer, {
        text: url,
        width: 160,
        height: 160,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
      });
    },
    getERCodeDatas() {
      //获取二维码链接
      let dataForm = {
        order_type: "",
        order_price: "",
        order_name: "",
        extension: ""
      };
      /*getPayERCode(dataForm).then((result) => {
        console.log(JSON.stringify(result));
         this.$nextTick(() => {
          this.showQRCode(result);
        });
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })*/
      this.$nextTick(() => {
        this.showQRCode("https://wallimn.iteye.com");
      });
      console.log(this.currentPrice + "--" + this.payType);
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //立即支付
    submitPay() {
      console.log("立即支付");
    },
    //充值
    goPay() {
      this.$router.push({ path: "/pay" });
      this.$store.commit("headerMenuId", null);
      this.$store.commit("asideMenuId", 2);
    }
  }
};
</script>

<style lang="scss" scoped>
.font-orange {
  font-size: 26px;
  color: #ffae00;
}
.qrcode-way {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .mpay-qrcode-container {
    position: relative;
    width: 160px;
    height: 160px;
    padding: 11px;
    text-align: center;
    background: url(../../../assets/images/pay/codeBox.png) no-repeat;
    background-size: contain;
  }
  .qrcode-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
.mpay-qrcode-tips {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.pay-info-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  .need-to-pay {
    font-size: 24px;
    color: #fa2323;
    font-weight: 700;
    margin: 0 8px 0 2px;
  }
}
.qr-tip {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}
.pay-btn-box {
  margin-top: 30px;
  margin-left: 20px;
  .el-button {
    width: 100px;
    line-height: 36px;
    height: 36px;
    padding: 0;
    text-align: center;
  }
}
.topay {
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 14px;
  color: #333;
  .link {
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>